.mui-tabs__bar {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  background-color: transparent;
  white-space: nowrap;
  overflow-x: auto;
}

.mui-tabs__bar > li {
  display: inline-block;
}

.mui-tabs__bar > li > a {
  display: block;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  height: 48px;
  line-height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mui-tabs__bar > li > a:hover {
  text-decoration: none;
}

.mui-tabs__bar > li.mui--is-active {
  border-bottom: 2px solid #2196F3;
}

.mui-tabs__bar > li.mui--is-active > a {
  color: #2196F3;
}

.mui-tabs__bar.mui-tabs__bar--justified {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.mui-tabs__bar.mui-tabs__bar--justified > li {
  display: table-cell;
}

.mui-tabs__bar.mui-tabs__bar--justified > li > a {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}

.mui-tabs__pane {
  display: none;
}

.mui-tabs__pane.mui--is-active {
  display: block;
}