.container {
    -ms-overflow-style: none;
}

.card-header {
    padding-left: 0;
    font-size: 20px;
    font-weight: 500;
}

.srp-card-header {
    padding-bottom: 0.5rem;
    font-size: 20px;
    font-weight: 500;

}

.srp-card-body {
    border: none;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}

.srp-card-summary {
    padding: 1.25rem;
    color: rgba(255, 255, 255);
    background-color: rgb(25, 93, 147);
}

.srp-card-outage-no-outage {
    padding: 1.25rem;
    color: rgba(255, 255, 255, 0.537);
    background-color: rgb(115,123,76);
}

.srp-card-outage-closed-account {
    padding: 1.25rem;
    color: rgba(255, 255, 255, 0.537);
    background-color: rgb(116,116,116);
}


.srp-card-summary.srp-alert {
    background-color: rgba(167, 63, 64, 1);
    color: rgba(255, 255, 255, 0.537);
}

.srp-card-summary.srp-inactive {
    background-color: rgba(116, 116, 116, 1);
    color: rgba(255, 255, 255, 0.537);
}

.srp-card-details {
    padding: 1.25rem;
}

.srp-red, .srp-mpower-red {
    background-color: rgb(167,63,64);
}

.srp-light-red {
    background-color: rgba(235, 212, 212, 1);
}
.srp-gray {
    background-color: rgb(116,116,116);
}

.srp-green, .srp-mpower-green {
    background-color: rgb(115,123,76);
}

.srp-blue {
    background-color: rgb(25,93,147);
}

.srp-orange{
    background-color:rgb(209,149,25);
}

.srp-mpower-yellow {
  background-color: #B1621C;
}

.srp-mpower-orange {
    background-color: rgb(194, 86, 22);
}

.srp-green-text {
    color: rgb(115,123,76);
}

.srp-red-text {
    color: rgb(167,63,64) !important;
}
.srp-blue-text {
  color: #004B87 !important;
}
.srp-light-blue-text{
  color: #6693B7 !important
}
footer.nav {
    -moz-box-shadow: 0px -3px 3px rgba(211, 211, 211, .5);
    -webkit-box-shadow: 0px -3px 3px rgba(211, 211, 211, .5);
    box-shadow: 0px -3px 3px rgba(211, 211, 211, .5);
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {display:none;}

  /* Hide default HTML checkbox but user can still tab into it */
  .keybd-navigable.switch input {
    display: block;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider, .yesNoToggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 34px;
    width: 65px;
  }

  .slider:before, .yesNoToggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 50%;
  }

  input:checked + .slider, input:checked + .yesNoToggle {
    background-color: rgb(33,108,224);
  }

  .keybd-navigable input:not(checked):focus + .slider,
  .keybd-navigable input:not(checked):focus + .yesNoToggle {
    background-color: rgb(192,192,192);
  }

  .keybd-navigable input:checked:focus + .slider,
  .keybd-navigable input:checked:focus + .yesNoToggle {
    background-color: rgb(32,94,211);
  }

  input:focus + .slider, input:focus + .yesNoToggle {
    box-shadow: 0 0 1px rgb(33,108,224);
  }

  input:checked + .slider:before, input:checked + .yesNoToggle:before {
    -webkit-transform: translateX(31px);
    -ms-transform: translateX(31px);
    transform: translateX(31px);
  }

  input:disabled + .slider, input:disabled + .yesNoToggle {
    cursor: not-allowed;
  }

  .slider:after
  {
   content:'OFF';
   color: black;
   display: block;
   position: absolute;
   transform: translate(-50%,-50%);
   top: 50%;
   left: 70%;
   font-size: 12px;
  }

  input:checked + .slider:after
  {
    content:'ON';
    color: white;
    right: 45%;
    left: 30%;
  }

  .slider.es:after
  {
    content:'NO';
  }

  input:checked + .slider.es:after
  {
    content:'SÍ';
  }

  .yesNoToggle:after
  {
   content:'NO';
   display: block;
   position: absolute;
   transform: translate(-50%,-50%);
   top: 50%;
   left: 70%;
   font-size: 12px;
  }

  input:checked + .yesNoToggle:after
  {
    content:'YES';
    color: white;
    right: 50%;
    left: 30%;
    width: 20px;
  }

  input:checked + .yesNoToggle.es:after
  {
    content:'SÍ';
    left: 42%;
  }

  .axis .domain {
    display: none;
  }
  .axis .tick line{
      opacity: 0;
  }
  .axis .tick text{
    fill: #707070;
    font-size: 14px;
  }

  .date-axis .domain {
    display: none;
  }
  .date-axis .tick line{
      opacity: 0;
  }
  .usage-date-axis .domain{
      display: none;
  }
  .usage-date-axis .tick text{
    fill: #707070;
    font-size: 14px;
  }
  .usage-date-axis-time .tick text{
    fill: #707070;
    font-size: 14px;
  }
  .usage-date-axis-time .tick line{
      opacity: 0;
  }
  .usage-date-axis-time .domain{
    display: none;
}
  .chart-axis-label{
    fill: #707070;
    text-anchor: middle;
  }
  .date-axis .tick text{
    fill: #707070;
    font-size: 14px;
  }
  .date-axis-md .domain {
    display: none;
  }
  .date-axis-md .tick line{
      opacity: 0;
  }
  .date-axis-md .tick text{
    fill: #707070;
    font-size: 12px;
  }
  .chart-legend-item-hidden{
    display: none;
    width: 50%;
    height: 30px;
  }
  .chart-legend-item-show{
    height: 100%;
    display: inline-block;
    font-size: 12px;
    padding-right: 14px;
  }
  .chart-legend-container{
      position:relative;
  }
  .chart-legend-circle{
    border-radius: 50%;
	width: 8px;
    height: 8px;
    display: inline-block;
    margin-left: 5px;
  }
  .viz-chart{
    display: block;
  }
  .viz-legend-text{
      fill: #212121;
      font-size: 12px;
      display: inline;
  }
  g.viz-total, rect.viz-Total, div.viz-Total{
      fill: #6693B7;
      background: #6693B7;
 }
 .viz-total > g.viz-total-credit, rect.viz-total-credit, div.viz-total-credit{
    fill: rgba(171, 175, 147, 1);
    background: rgba(171, 175, 147, 1);
}
.viz-total > g.viz-total-credit .current-hover{
    fill: #8F956F;
}


  g.viz-superOffPeak, rect.viz-SuperOffPeak, div.viz-SuperOffPeak, g.viz-superOffPeak-generation{
      fill: #BF90AF;
      background: #BF90AF;
  }
  g.viz-offPeak, rect.viz-OffPeak, div.viz-OffPeak, g.viz-totalUsageCharges{
      fill: #6693B7;
      background: #6693B7;
  }
  g.viz-offPeak-generation, rect.viz-OffPeak-generation, div.viz-OffPeak-generation, g.viz-total-generation{
    fill: #ABAF93;
    background: #ABAF93;
}
  g.viz-onPeak, rect.viz-OnPeak, div.viz-OnPeak{
      fill: #B15455;
      background: #B15455;
  }
  g.viz-onPeak-generation, rect.viz-OnPeak-generation, div.viz-OnPeak-generation{
    fill: #E0B866;
    background: #E0B866;
}
.usage-chart-legend-circle.generation.viz-OnPeak{
    background: #E0B866;
}
.usage-chart-legend-circle.generation.viz-OffPeak, .usage-chart-legend-circle.generation.viz-Total{
    background: #ABAF93;
}
  g.viz-shoulder, rect.viz-Shoulder, div.viz-Shoulder,  g.viz-shoulder-generation, rect.viz-Shoulder-generation, div.viz-Shoulder-generation{
      fill: #EBAF8B;
      background: #EBAF8B;
  }
  g.viz-max, g.viz-max-generation{
      fill: #F2F2F2;
      pointer-events: none;
  }
  g.viz-min{
      fill: #F2F2F2;
      pointer-events: none;
  }
  g.viz-superOffPeak .current-hover{
      fill: #A6648F;
      cursor: pointer;
  }
  g.viz-total .current-hover{
      fill: #326E9F;
      cursor: pointer;
  }
  g.viz-offPeak .current-hover{
      fill: #326E9F;
      cursor: pointer;
      shape-rendering: auto;
  }
  g.viz-offPeak-generation .current-hover{
    fill: #8F956F;
    cursor: pointer;
 }
  g.viz-totalUsageCharges .current-hover{
    fill: #326E9F;
    cursor: pointer;
    }
  g.viz-onPeak .current-hover{
      outline: none;
      fill: #9E2A2B;
      cursor: pointer;
  }
  g.viz-onPeak-generation .current-hover{
    fill: #D6A132;
    cursor: pointer;
  }
 g.viz-total-generation .current-hover{
  fill: #8F956F;
  cursor: pointer;
  }
  g.viz-superOffPeak-generation .current-hover{
    fill: #A6648F;
    cursor: pointer;
}
  g.viz-positive, rect.viz-positive{
      fill: #4FC3F7;
  }
  g.viz-shoulder .current-hover{
      fill: #E38F5D;
      cursor: pointer;
  }
  g.viz-shoulder-generation .current-hover{
    fill: #E38F5D;
    cursor: pointer;
}
  g.viz-negative, rect.viz-negative{
      fill: #FF8A80;
  }
  g.viz-invisible{
      display: none;
  }
  
  #dailyDashChartContainer {
    position: relative;
  }
  #usagePageChartContainer {
    position: relative;
  }
  #monthlyDashChartContainer {
    position: relative;
  }
  #chartContainer {
    position: relative;
    top: 0;
    left: 0;
    margin: auto;
  }
  .viz-tooltip-main{
      position: absolute;
      width: auto;
      height: auto;
      left: 0px;
      bottom: 10px;
      white-space: nowrap;
      z-index: 10;
      margin-right: -200px

  }
  .viz-tooltip-inner {
      position: relative;
      bottom: 0px;
      right: 50%;
      background: rgb(84, 110, 122);
      color: #FFF;
      padding: 10px;
      border-radius: 10px;

  }
  .viz-tooltip-arrow {
      content: "";
      position: relative;
      border-width: 10px;
      border-style: solid;
      border-color:  rgb(84, 110, 122) transparent transparent transparent;
      width:0px;
      height:0px;

  }
  .viz-tooltip-arrow-main{
      position:absolute;
      height:20px;
      width: 20px;
      display: none;
  }

  .tooltip-inner {
      background-color: rgb(84, 110, 122);
  }

  .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
    border-top-color: rgb(84, 110, 122);
}
.center-text{
    text-align: center;
}
.mobile-chart-label{
    font-size: 15pt;
}
.high-demand-box{
    word-wrap: break-word;
    width:100%;
    text-align: center;
    background-color: #99b3cf;
    color: #333333;
    padding-top: 5px;
    margin:0
}

.usage-card-buttons{
    padding: 1.25em 1.25em 0 1.25rem;
}


.srp-modal-header {
    border: 0;
    padding: 0;
    position: relative;
    margin-bottom: 20px;
}

.srp-modal-close-btn {
  border-width: 0;
}

.srp-modal-close {
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 1;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./../images/nav/close/close_default.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
}

.srp-modal-close :hover {
    background-image: url("./../images/nav/close/close_hover.svg");
    background-repeat: no-repeat;
    cursor: pointer;
}

.srp-modal-close :active {
    background-image: url("./../images/nav/close/close_click.svg");
    background-repeat: no-repeat;
    cursor: pointer;
}

.srp-modal-body {
    margin: 30px;
}

.srp-modal-body-scroll {
    margin: 30px;
    padding: 0px 15px 0px 15px;
    overflow:auto;
}

.srp-modal {
    flex: 0 1 auto;
    margin: 32px;
    display: flex;
    position: relative;
    max-height: 90vh;
    overflow-y: visible;
    flex-direction: column;
    max-width: 600px;
}

.srp-modal-footer {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
}

.srp-green-button {
    background-color: #26A947;
    border-color: #26A947;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}
.srp-new-tag{
  border-width: 0px;
  padding-left: 10px;
  width: 146px;
  height: 21px;
  display: flex;
  font-family: 'FuturaPT-Bold', 'Futura PT Bold', 'Futura PT', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #FFFFFF;
  text-align: left;
  vertical-align: top;
}
.srp-new-tag-div{
  border-width: 0px;
  border-top: none;
  position: relative;
  width: 46px;
  height: 21px;
  background: inherit;
  background-color: rgb(115, 123, 76);
  border: none;
  border-radius: 4px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'FuturaPT-Bold', 'Futura PT Bold', 'Futura PT', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
}
srp-new-tag-text{

  align-self: center;
  padding: 0px 8px 1px 8px;
  box-sizing: border-box;
  width: 100%;
  border-width: 0px;
  white-space: nowrap;
  text-transform: none;

}
.srp-new-tag-arrow-top {
  margin-top: 10px;
  background-color: rgba(115, 123, 76, 1);
  margin-left: 39%;
}

.srp-new-tag-arrow-top:after {
  content: " ";
  position: absolute;
  right: 12px;
  top: -8px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid  rgba(115, 123, 76, 1);;
}
.no-wrap{
  white-space: nowrap;
}

/* #dailyChartMainGroupDashboard, #monthlyChartMainGroupDashboard{
  stroke-width : 2.5px;
  stroke : white;
} */

#dailyChartMainGroupDashboard g.viz-onPeak {
  fill: #9E2A2B;
  background: #9E2A2B;
}

#dailyChartMainGroupDashboard g.viz-onPeak .current-hover {
  fill: #7E2122;
  background: #7E2122;
}

#dailyChartMainGroupDashboard g.viz-offPeak, 
#dailyChartMainGroupDashboard g.viz-total, 
#monthlyChartMainGroupDashboard g.viz-total {
  fill: #99B7CF;
  background: #99B7CF;
}

#dailyChartMainGroupDashboard g.viz-offPeak .current-hover,
#dailyChartMainGroupDashboard g.viz-total .current-hover, 
#monthlyChartMainGroupDashboard g.viz-total .current-hover {
  fill: #6693B7;
  background: #6693B7;
}

#dailyChartMainGroupDashboard g.viz-superOffPeak {
  fill: #80225F;
  background: #80225F;
}

#dailyChartMainGroupDashboard g.viz-superOffPeak .current-hover {
  fill: #661B4C;
  background: #661B4C;
}

#dailyChartMainGroupDashboard g.viz-shoulder {
  fill: #EFDBB2 ;
  background: #EFDBB2 ;
}

#dailyChartMainGroupDashboard g.viz-shoulder .current-hover {
  fill: #E5C47F;
  background: #E5C47F;
}

#dailyDashChartLegendContainer #onPeakLegendItem .chart-legend-circle.viz-OnPeak{
  fill: #9E2A2B;
  background: #9E2A2B;
}

#dailyDashChartLegendContainer #superOffLegendItem .chart-legend-circle.viz-SuperOffPeak{
  fill: #80225F;
  background: #80225F;
}

#dailyDashChartLegendContainer #offPeakLegendItem .chart-legend-circle.viz-OffPeak{
  fill: #99B7CF;
  background: #99B7CF;
}

#dailyDashChartLegendContainer #shoulderLegendItem .chart-legend-circle.viz-Shoulder{
  fill: #EFDBB2 ;
  background: #EFDBB2 ;
}

#dailyDashChartLegendContainer #totalLegendItem .chart-legend-circle.viz-Total{
  fill: #99B7CF;
  background: #99B7CF;
}

#monthlyDashChartLegendContainer #totalLegendItem .chart-legend-circle.viz-Total{
  fill: #4C81AB;
  background: #4C81AB;
}