.chart-type-btn {
    background-color: white;
    color: #087ED8;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-right: 10px;
}

.chart-type-btn-selected {
    background-color: #087ED8;
    color: white;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    margin-right: 10px;
}
.viz-max > .viz-on-off-shoulder{
    cursor: pointer;
    pointer-events: all;
}
.usage-chart-legend-item-show{
    height: 100%;
    display: inline-block;
    padding-right: 14px;
    display: inline-flex;
    align-items: center;
}

.usage-chart-legend-circle{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-left: 5px;
}

.usage-chart-legend-line{
    border-radius: 5px;
    width: 20px;
    height: 4px;
    display: inline-block;
    margin-left: 5px;
    text-align: center;
    line-height: normal;
}
div.viz-high-temp{
    background: #DF7F46;
}

div.viz-low-temp{
    background: #326E9F;
}
div.viz-Total{
    background: #6693B7;
}
path.viz-high-temp{
    stroke: #DF7F46;
}

path.viz-low-temp{
    stroke: #326E9F;
}
g.viz-onPeak > g.viz-high-demand-bar{
    fill: #9E2A2B;
}
g.viz-total > g.viz-high-demand-bar{
    fill: #326E9F;
}

@media (max-width: 767px) {
    .chart-type-btn {
        padding-top:4px;
        padding-bottom:4px;
        padding-right:16px;
        padding-left:16px;
    }
    
    .chart-type-btn-selected {
        padding-top:4px;
        padding-bottom:4px;
        padding-right:16px;
        padding-left:16px;
    }
}
.chart-btn-container{
    margin-left: 70px;
}
.chart-date-picker input{
    color: #087ed8;
}
.chart-date-picker span.material-icons {
    color: #087ed8;
}

.usage-chart-error{
   padding-bottom: 60px;
   padding-top: 60px;
   
}
.usage-chart-error i{
    font-size: 48px;
    color: rgb(158, 42, 43);
}

.usage-chart-error-title{
    font-size: 24px;
    color: rgb(158, 42, 43);
}
.usage-chart-info{
    padding-bottom: 60px;
    padding-top: 60px;
    padding-right: 15%;
    padding-left: 15%;
    /* display: flex; */
    justify-content: center;
    text-align: center;
 }
 .usage-chart-info i{
     font-size: 48px;
     color: #004B87;
 }
 
 .usage-chart-info-title{
     font-size: 24px;
     color: #004B87;
 }
.monthly-chart-error{
    padding-bottom: 10px;
    padding-top: 10px;
    
 }
 .monthly-chart-error i{
     font-size: 48px;
     color: rgb(158, 42, 43);
 }
 
 .monthly-chart-error-title{
     font-size: 18px;
     font-weight: 800;
     color: rgb(158, 42, 43);
 }
 .demand-info-card{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: rgb(246, 246, 246);
    background-clip: border-box;
    border-radius: .25rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 500;
 }
 .flex-column{
     display: flex;
     flex-direction: column;
     font-size: 1rem;
 }
 