div.body-content {
    background: #f6f6f6;
}

nav.srp-menu, nav.srp-responsive-menu {
    background: #0066a5;
}

div.srp-logo-search div.navbar-nav a:not(:last-child) {
    border-right: solid 1px #9c9e93;
}

.mui-button,
button.displayAsText {
    background: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
    padding: 0;
}

.mui-button:focus,
.mui-button:hover {
    background-color: rgba(169,169,169,0.4);
    border-radius: 100%;
    outline: 0;
}

.mui-button:active {
    background-color: rgba(169,169,169,0.8);
    border-radius: 100%;
}

button.displayAsBlueText {
    background: transparent;
    border: 0;
    color: #087ed8;
    cursor: pointer;
    font: inherit;
    padding: 0;
}

button.displayAsLink, a {
    background: transparent;
    border: 0;
    color: #125590;
    cursor: pointer;
    font: inherit;
    padding: 0;
    text-decoration: underline;
}

button.displayAsLink:hover, a:hover { 
    color: #0086BE;
}   

button.displayAsLink:focus, a:focus {
    color: #0086BE;
    outline: 0;
}

a.navbar-brand img {
    width: 100px;
}

nav.srp-responsive-menu div.navbar-nav {
    flex-direction: row;
}

div.navbar-nav a img {
    width: 20px;
}

#srp-emergency-alert {
    /* #d9edf5 */
    --srp-red: 179, 58, 58;
    background: rgb(var(--srp-red));
    padding-bottom: 30px;
    padding-top: 30px;
    color: #fff;
    font-size: 1.125rem;
}

#srp-emergency-alert img {
    height: 90px;
}

@media print {

    @page {
        size:auto;
    }

    html, body {
        width: 992px;
    }

    body {
        margin:0px;
    }

    #section-to-print-cix, #section-to-print-cix *, #section-to-print-payment-submitted * {
        visibility: visible;
        font-size: x-large;
    }
    #section-to-print-cix, #section-to-print-payment-submitted {
        position: fixed;
        top: 0px;
        left: 0px;
    }

    #section-to-print-payment-submitted {
        visibility: visible;
        font-size: x-large;
    }
}

.word-break {
    overflow-wrap: break-word;
    word-break: break-all; /*IE*/
}