/* shared-footer font-family setting is needed until we sync up our fonts with srpnet.com */
#srp-shared-footer,
#srp-shared-footer h1,
#srp-shared-footer h2,
#srp-shared-footer h3,
#srp-shared-footer h4,
#srp-shared-footer h5,
#srp-shared-footer p,
#srp-shared-footer ol li,
#srp-shared-footer ul li {
    font-family: "SRPsans-Medium", helvetica, arial, sans-serif !important;
}

/* Horizontal layout */
.nav-link, .nav-link.active {
    text-decoration: none;
}

.nav-link:hover, nav-link.active:hover {
    text-decoration: underline;
    color: #0076A8;
}

.myaccount-nav .nav-item {
    border-bottom: 1px solid;
    border-color: lightgray;
    width: 100%;
    position: relative;
    display: inline-block;
    align-content: center;
}

.myaccount-nav .nav-item:has(> .nav-link.active) {
    border-bottom: 3px solid #125590;
    color: black;
}

.myaccount-nav .nav-item:hover {
    background-color: #e6f2fb;
}
.myaccount-nav .nav-item .nav-link:focus {
    background-color: #a2cef0;
}

.myaccount-nav .nav-item .nav-link .nav-title {
    font-size: 0.9375rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
}

.myaccount-nav .nav-item .nav-link.active .nav-title {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #48484A;
    text-align: center;
    width: 100%;
}

.myaccount-nav .nav-item .nav-link .nav-description {
    font-size: 12px;
    font-weight: 400;
    color: #707070;
    text-align: center;
    width: 100%;
}

.myaccount-nav .nav-item .nav-link .nav-img {
    width: 22px;
    height: 22px;
}

.myaccount-profile-tab {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
    text-align: center;

    border: #cfcfd0 1px solid;
    border-radius: 10px;

    color: #087ED8;
    text-decoration: none;
    padding: .25em .5em;
    margin-left: .5em;
    margin-right: .5em;
}

.myaccount-profile-tab:hover {
    text-decoration: none;
    background-color: #e6f2fb;
}

.myaccount-profile-tab:focus {
    text-decoration: none;
    background-color: #a2cef0;
}


.myaccount-profile-tab.active {
    border: #087ED8 2px solid;
    color: black;
    text-decoration: none;
    background-color: transparent;
}

.myaccount-profile-tab.active:hover {
    background-color: #e6f2fb;
}

.myaccount-in-card-message {
    border-radius: 4px;
    margin: 10px 0px 10px 0px;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
}

.myaccount-close {
    cursor: pointer;
}

.myaccount-pointer {
    cursor: pointer;
}

/*responsive navigation*/
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 75, 135, 1);
    overflow-x: hidden;
}

.overlay-content {
    position: relative;
    top: 30px;
    width: 100%;
    text-align: left;
}

.overlay a {
    padding: 6px;
    text-decoration: none;
    font-size: 1.125rem;
    display: block;
}

.overlay-content div {
    padding: 3px;
    text-decoration: none;
    display: block;
}

.overlay-content div:hover {
    background-color: rgb(0, 102, 165);
}

.overlay-link {
    color: white;
}

.overlay-link:hover {
    color: white;
}

.overlay .closebtn {
    position: relative;
    padding: 12px;
    top: 20px;
    font-size: 1.125rem;
}

.overlay-link .row {
    align-items: center;
    padding: 0;
}

.overlay-link .nav-icon {
    height: 30px;
    width: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.overlay-link .nav-title {
    font-size: 1.125rem;
    font-weight: 800;
    padding: 0;
}

.overlay-link .nav-description {
    font-size: 1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    padding: 0;
}

@media (max-width: 991px) and (min-width: 768px) {
    .overlay a {
        padding: 6px;
        text-decoration: none;
        font-size: 1.5rem;
        display: block;
    }

    .overlay .closebtn {
        position: relative;
        padding: 12px;
        top: 20px;
        font-size: 1.5rem;
    }

    .overlay-link .nav-icon {
        height: 40px;
        width: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .overlay-link .nav-title {
        font-size: 1.5rem;
        font-weight: 800;
        padding: 0;
    }

    .overlay-link .nav-description {
        font-size: 1.25rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        padding: 0;
    }
}


/*account selector*/
.myaccount-nav-as-dropdown-25 {
    width: 25%;
}

/* inline row label */
.srp-row .srp-label {
    color: rgb(112, 112, 112);
    font-size: 16px;
    font-weight: bold;
}

/* Material icon colors */
.material-icons.orange-face {
    color: rgb(245, 145, 0)
}

.account-selector.water-drop {
    height: 24px;
    width: 24px;
}

.align-bottom {
    vertical-align: bottom;
}

.align-middle {
    vertical-align: middle;
}

.menu .tk-futura-pt h1 h2 h3 h4 h5 ol li p ul li {
    font-family: "futura-pt",helvetica,arial,sans-serif !important;
}

body {
    font-family: "futura-pt",helvetica,arial,sans-serif ;
}

h3 {
    font-size: 32px!important;
}

h5 {
    font-weight: 200!important;
}

.md-24 {
    font-size: 24px;
}

.md-30 {
    font-size: 30px;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.font-50 {
    font-size: 50px;
}

.fw-700 {
    font-weight: 700;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.w-50 {
    width: 50px;
}

.srp-lh-1 {
    line-height: 1rem;
}

.payment-nav .nav-item {
    width: 100%;
    position: relative;
    display: inline-block;
    color: rgb(112, 112, 112);
}

.payment-nav .nav-item:hover {
    background-color: #f1f1f1;
}

.payment-nav .nav-item .nav-link div {
    color: rgb(112, 112, 112);
}

.payment-nav .nav-item .nav-link .icon {
    display: block;
}

.payment-nav .nav-item .nav-link .iconSelected {
    display: none;
}

.payment-nav .nav-item .nav-link {
    color: rgb(112, 112, 112);
    font-weight: 800;
    font-size: 14px;
}

.payment-nav .nav-item .nav-link.active {
    background-color: rgb(8,126,216);
    border-radius: 4px;
    color: white;
    height: 100%;
}

.payment-nav .nav-item .nav-link-mobile.active {
    background-color: rgb(8,126,216);
    border-radius: 16px;
    color: white;
    height: 100%;
}

.payment-nav .nav-item .nav-link.active div {
    color: white;
}

.payment-nav .nav-item .nav-link.active .icon {
    display: none;
}

.payment-nav .nav-item .nav-link.active .iconSelected {
    display: block;
}

#techSupMessage::-webkit-input-placeholder {
    color: rgba(112, 112, 112, 0.537);
}

#techSupMessage:-moz-placeholder {
    color: rgba(112, 112, 112, 0.537);
}

#techSupMessage:-ms-input-placeholder {
    color: rgba(112, 112, 112, 0.537);
}

.techHintText {
    font-size: 14px;
}

.success-alert-details {
    padding: 0.5rem;
    color: #424242;
}

.success-alert-body-r,
.success-outage-alert-body {
    border: 1px solid;
    border-color: #ebd4d4;
    background-color: #ebd4d4;
    border-radius: 3px;
}

.srp-alert-warn{
    background-color: #FFF3CD;
    color: #333333;
    border-color: #FFEEBA;
    border-width: 1px;
    border-radius: 4px;
    padding: 10px;
}

.srp-alert-warn :is(h1, h2, h3, h4, h5){
    color: #856404;
}

.srp-alert-error{
    background-color: #F8D7DA;
    color: #333333;
    border-color:#F5C6CB;
    border-width: 1px;
    border-radius: 4px;
    padding: 10px;
}

.srp-alert-error :is(h1, h2, h3, h4, h5){
    color:#721C24;
}

.srp-alert-notice{
    background-color: #CCE5FF;
    color: #333333;
    border-color: #B8DAFF;
    border-width: 1px;
    border-radius: 4px;
    padding: 10px;
}

.srp-alert-notice-DCO{
    background-color: #E5EDF3 ;
    color: #333333;
    border-color: #B8DAFF;
    border-width: 1px;
    border-radius: 4px;
    padding: 10px;
}

.srp-alert-notice :is(h1, h2, h3, h4, h5){
    color:#004085;
}

.srp-alert-success{
    background-color: #D4EDDA;
    color: #333333;
    border-color:#C3E6CB;
    border-width: 1px;
    border-radius: 4px;
    padding: 10px;
}

.srp-alert-success :is(h1, h2, h3, h4, h5){
    color:#155724;
}

.srp-alert-general-green{
    background-color: #f1f1ed;
    color: #737B4C;
    border-radius: 4px;
    padding: 10px;
}


.srp-alert-general-orange{
    background-color: #f7dfd0;
    color: #424242;
    border-radius: 4px;
    padding: 10px;
}

.srp-light-blue-background {
    background-color: rgb(229,237,243);
}

.srp-navy-background {
    background-color: #004987;
}

.srp-dark-blue-color {
    color: #004b87;
}

.srp-navy-color {
    color: #004987;
}

.srp-blue-color {
    color: rgb(8,126,216);
}

.srp-red-color {
    color: rgb(158,42,43);
}

.srp-orange-color {
    color: #f7dfd0;
}

.srp-gray-color{
    color: #707070;
}

.srp-dark-gray-color{
    color: #48484a60;
}

.srp-orange-background {
    background-color: #f7dfd0;
}

.srp-subscription-warn{
    background-color: #f7dfd0;
    color: #424242;
    border-radius: 4px;
    padding: 10px;
}
.hidden-element{
    display:none;
}
.block-element{
    display: block;
}

.srp-dark-blue-1 {
    color: rgb(0,75,135) !important;
}

.srp-olive-green {
    color: rgb(115,123,76) !important;
}

.srp-100-red {
    color: #9E2A2B
}

.srp-3x-font-size {
    font-size: 3rem
}

@media (min-width: 992px) {
    .btn.srp-btn {
        line-height: 1.2rem;
        padding: 0.3rem 1rem;
        height: 2rem;
    }

    .btn.srp-btn.btn-dynamic-height {
        height: auto;
    }
}

@media (max-width: 767px) {
    .btn.srp-btn div[role="progressbar"] {
        position: relative;
        top: 0.2rem;
    }
}

.btn.srp-btn {
    vertical-align: middle;
    font-family: "futura-pt",helvetica,arial,sans-serif;
    text-decoration: none;
}

.btn.srp-btn:focus, .btn.srp-btn:active {
    outline: none;
    box-shadow: none;
}

.btn-blue, .btn-blue-purchase-history {
    background-color: #0076A8 !important;
    border-color: #0076A8 !important;
    color: #ffffff !important;
    text-transform: none !important;
}

.btn-blue:active, .btn-blue:focus,  
.btn-blue-purchase-history:not([disabled]):active,
.btn-blue-purchase-history:not([disabled]):focus {
    background-color: #055694 !important;
    border-color: #055694 !important;
    text-decoration: none;
}

.btn-lightblue {
    background-color: white !important;
    border: 1px solid #0076A8 !important;
    color: #0076A8 !important;
    text-transform: none !important;
}               

.btn-lightblue:active, .btn-lightblue:focus {
    background-color: #DCECF9 !important;
    text-decoration: none;
}

.btn-green, .btn-green-cdd {
    background-color: #218838  !important;
    border-color: #218838  !important;
    color: white !important;
    text-transform: none !important;
}

.btn-green:active, .btn-green:focus,
.btn-green-cdd:not([disabled]):active,
.btn-green-cdd:not([disabled]):focus {
    background-color: #19692C !important;
    border-color: #19692C !important;
}

.btn-orange {
    background-color: #B1621C !important;
    border-color: #B1621C !important;
    color: white !important;
    text-transform: none !important;
}

.btn-orange:active, .btn-orange:focus {
    background-color: #AC4C13 !important;
    border-color: #AC4C13 !important;
}

@media (min-width: 768px) {
    .btn-blue-purchase-history:not([disabled]):hover {
        background-color: #0665ac !important;
    }

    .btn-blue:hover {
        background-color: #125590 !important;
        border-color: #125590 !important;
    }

    .btn-lightblue:hover {
        background-color: #0076A8 !important;
        color:#ffffff !important;
    }
    .btn-lightblue:disabled:hover {
        background-color: white !important;
        border: 2px solid #087ED8 !important;
        color: #087ED8 !important;
        cursor: not-allowed !important;
    }

    .btn-green-cdd:not([disabled]):hover {
        background-color: #22983F !important;
        border-color: #22983F !important;
    }

    .btn-green:hover {
        background-color: #1E7E34 !important;
        border-color: #1E7E34 !important;
    }

    .btn-orange:hover {
        background-color: #C25615 !important;
        border-color: #C25615 !important;
    }
}

.srp-hide {
	display: none;
}

.callMeAnyTimeLink {
    background-color: #6e8bb0;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
}

.callMeAnyTimeLink a {
    color: white;
}

/* https://stackoverflow.com/questions/31184000/making-a-bootstrap-table-column-fit-to-content */
.table th.srp-fit,
.table td.srp-fit {
    white-space: nowrap;
    width: 1%;
}

.material-icons.srp-icon-red-color {
    color: rgb(158,42,43,1);
}

.material-icons.srp-icon-color {
    color: #125590;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-20 {
    font-size: 20px;
}

.text-overflow-center {
    text-align: center;
    word-break: normal;
}

.srp-tooltip-custom {
    background: rgb(84, 110, 122) !important;
    border-radius: 10px !important;
    max-width: 230px !important;
    text-align: center;
}

.srp-rounded-outline {
    border: 1px solid grey;
    border-radius: 10px;
}

.srp-tooltip-custom.show {
    opacity: 1 !important;
}

.srp-tooltip-custom:after {
    border-top-color: rgb(84, 110, 122) !important;
}

.srp-tooltip-custom.place-bottom:after {
    border-bottom: 6px solid rgb(84, 110, 122) !important;
}

.verify-radio-button {
    margin-bottom: 0px !important;
    height: 34px !important;
    margin-left: -10px !important;
}

.mui_tab:hover {
    background-color: #e6f2fb !important;
}

.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.cmpp-shine {
    background-image: linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% );
    background-repeat: repeat-y;
    background-size: 50px 220px; /* highlight */
    background-position: 0 0; /* highlight */
    animation: shine 1s infinite;
}

@keyframes shine {
    to {
        background-position:
            100% 0, /* move highlight to right */
            0 0,
            0 40px,
            0 80px,
            0 120px;
    }
}

ul.srp-list {
    padding-left: 0px;
    list-style-position: inside;
}

.srp-list {
    color: rgb(103, 135, 183);
}

span.srp-list {
    color: #707070;
}

.mui-radio-label-color {
    color: rgba(0, 0, 0, 0.87);
}

.mui-agreement-checkbox-label {
    color: rgba(0, 0, 0, 0.87);
}

.multi-payment-responsive-sticky-counter {
    color: #ffffff;
    background-color: rgb(102, 102, 102);
    font-size: 14px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    border-bottom: 0.15rem solid rgb(255, 0, 255);
    z-index: 31415;
}

.summary-account-main {
    border: 1px solid rgb(179,195,219);
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
}

.summary-account-expand-open-control {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px
}

.summary-account-expand {
    color:rgb(8,126,216);
    font-size: 16px;
    font-weight: 400;
    padding: 0px;
}

.summary-account-expand.expanded {
    border-bottom: 1px solid rgb(179,195,219);
}

.summary-account-expand-responsive {
    color:rgb(8,126,216);
    font-size: 14px;
    font-weight: 400;
    border-top: 1px solid rgb(8,126,216);
}

.summary-account-parent-chip {
    border: 1px solid rgb(128,34,95);
    border-radius: 16px;
    height: 95%;
    color: rgb(128,34,95);
    font-size: 14px;
    width: 65px;
    font-weight: 700;
    text-align: center;
}
.summary-billing-subaccount-row {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
}
.summary-billing-subaccount-row-not-added {
    font-size: 16px;
    color: #999999;
    display: flex;
    flex-wrap: wrap;
}

.summary-billing-subaccount-row-not-added-responsive {
    font-size: 18px;
    color: #999999;
}

.summary-billing-parent-expanded {
    border-top: 1px solid #888888;
}

.summary-billing-parent-collapsed {
    border-bottom: 1px solid #ffffff;
}

.summary-billing-parent-collapsed:hover {
    border-bottom: 1px solid #888888;
    box-shadow: 0px 5px 7px #888888;
}

.summary-billing-row-responsive-container {
    border: 1px solid rgb(8,126,216);
    border-radius: 5px 5px 5px 5px;
}

.icon-no-left-padding {
    padding-left: 0px !important;
}

.profile-account-container {
    padding: 8px 23px 8px 23px;
}

.profile-account-number {
    min-width: 6em;
    font-size: 14px;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.profile-summary-subaccount-addaccount {
    cursor: pointer;
    font-size: 14px;
}

.associated-account-row-header-info-box {
    font-weight: 400;
    font-size: 16px;
    border-bottom: 3px solid rgba(0,75,135,1)
}

.associated-account-row-header-column-header {
    background-color: rgba(229,237,243,1);
    font-weight: 400;
    font-size: 16px;
}

.associated-account-row {
    font-size: 16px;
    color: #212529;
    border-bottom: 1px solid #ffffff;
    box-shadow: 0px 5px 7px #ffffff;
}

.associated-account-row:hover {
    border-bottom: 1px solid #888888;
    box-shadow: 0px 5px 7px #888888;
}

.associated-account-row .associated-account-row-action-buttons {
    visibility: hidden;
}

.associated-account-row:hover .associated-account-row-action-buttons {
    visibility: visible;
}

.associated-account-pagination {
    font-size: 16px;
    font-weight: 400;
}

.associated-account-pagination-buttons {
    padding: 0px !important;
}

.associated-account-more-button-responsive {
    padding: 0px 0px 0px 7px !important;
}

.associated-account-pagination-buttons:disabled {
    padding: 0px !important;
}

.associated-account-pagination-page-button {
    font-weight: 600;
}
.associated-account-pagination-page-button:disabled {
    color: black !important;
}

.associated-account-row-responsive-account-name {
    font-size: 18px;
    color: #212121;
    height: 30px;
}

.summary-billing-subaccount-row-responsive-account-name {
    font-size: 18px;
    color: #212121;
}

.associated-account-row-responsive-service-address {
    font-size: 13px;
    color: #212121;
}

.associated-account-row-responsive-action-button {
    padding-bottom: 0px !important;
}

.myaccount-popup-menu {
    background-color: white;
}

.generic-chip {
    border: 1px solid;
    border-radius: 16px;
    height: 95%;
    font-size: 14px;
    width: 65px;
    font-weight: 700;
    text-align: center;
}

.credit-advance-header {
    font-size: 18px;
    font-weight:600;
    color: #484848;
}

button.credit-advance-back {
    font-size: 16px;
    font-weight: 600;
    color: #087ED8;
    cursor: pointer;
}

button.credit-advance-back:hover {
    text-decoration: underline;
}

.credit-advance-text {
    font-size: 15px;
    font-weight: 600;
    color: #707070;
}

button.credit-advance-safety-statement {
    font-weight: 800;
    color: #087ED8;
    cursor: pointer
}

button.credit-advance-safety-statement:hover {
    text-decoration: underline;
}

.credit-advance-dollar {
    font-size: 40px;
    font-weight: 600;
    color: #004B87;
}

.credit-advance-details-section-header {
    font-size: 13px;
    font-weight: 600;
    color: #707070;
}

.credit-advance-details-section-text {
    font-size: 13px;
    font-weight: 600;
    color: #333333
}

.credit-advance-details-acknowledgement {
    font-size: 15px;
    font-weight: 500;
    color: #707070;
}

.credit-advance-popup-section-header {
    font-size: 16px;
    font-weight: 700;
    color: #707070;
}

.credit-advance-popup-text {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
}

.credit-advance-popup-text-responsive {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
    height: 400px;
    overflow-x: scroll;
}

.surepay-pending-chip {
    background-color: #F4E7CC;
    border: 1px solid #F4E7CC;
    border-radius: 16px;
    height: 95%;
    font-size: 14px;
    width: 65px;
    font-weight: 700;
    text-align: center;
    padding: 2px 8px 2px 8px;
    margin-left: 8px;
}

.checkmark-label-blue {
    font-size:16px;
    font-weight: 800;
    color: #087ED8;
}

ul.dash {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
}
ul.dash > li:before {
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
}
  
  .collapse-content.collapsed {
    display: none;
  }
  
  .collapsed-content.expanded {
    display: block;
  }
.expandedBorderStyle {
    border: 1px solid #087ED8;
    border-radius: 7px;
    
  }
.collapsedBorderStyle {
    border: none
  }
.materialCheck{
   font-family:"MaterialIcons-Regular", "Material Icons", sans-serif;
   font-weight:400;
   color:#424242;
}
.materialDots{
    font-family: "MaterialIcons-Regular", "Material Icons", sans-serif;
    font-weight: 400;
    color: #424242;
}

#app .text-muted {
    color: #636B73 !important;
}

.text-primary-ada {
    color: #125590  !important;
}

.text-primary-ada:hover, .text-primary-ada:focus{
    color: #0086BE   !important;
}

.srp-olive-green-ada {
    color: rgb(106,114,70) !important;
}

.reconnection-notice {
    color : #333333 ;
}

.reconnection-dark {
    color : #707070
}
