.outage-history-description::-webkit-scrollbar {
    display: none;
}

@media (min-width: 1200px) {
    .outage-history-description {
        max-height: 400px;
        overflow: auto;
    }
    .outage-history-fadeout {
        max-width: 350px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .outage-history-description {
        max-height: 376px;
        overflow: auto;
    }
    .outage-history-fadeout {
        max-width: 289.98px;
    }
}

.outage-history-fadeout-container {
    position: relative;
    height: 0;
}

.outage-history-fadeout {
    bottom: 0;
    height: 4em;
    background: linear-gradient(
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 1) 70%
    );
    position: absolute;
    width: 100%;
}

.outage-banners {
    background-color: rgb(243,246,249);
}

.outage-banner-icons {
    width: 2.5em;
    height: 2.75em;
}