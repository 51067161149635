.solar-choice-details {
    padding-top: 10px;
    padding-bottom: 15px;
    padding-right: 12px;
    padding-left: 12px;
}

.solar-choice-box {
    border-radius: 4px;
    background-color: rgb(241, 241, 237);
    width: 100%;
}