div.legend {
    background: rgba(72, 72, 74, 0.866666666666667);
    height: 600px;
    width: 300px;
    padding: 20px;
    font-size: 13px;
    position: relative;
    font-family: "futura-pt",helvetica,arial,sans-serif;
    font-size: 14px;
}

.legend-title {
    font-size: 18px;
    font-weight: 700;
}

.legend-header {
    font-size: 16px;
    font-weight: 700;
}

.legend-icons {
    height: 22px;
}

.legend-details-btn {
    background: 0;
    border: 0;
    outline: 0;
    font-size: 16px;
    font-weight: 800;
    cursor: pointer;
}

.legend-details-btn-container {
    font-size: 16px;
    font-weight: 800;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.legend-details-btn-container-position {
    position: absolute;
    bottom: 40px;
}

.show-legend-btn {
    margin-left: 15px;
    font-family: "futura-pt",helvetica,arial,sans-serif;
    background-color: white;
    border: 2px solid #087ED8;
    color: #087ED8;
    text-transform: none;
}

.show-legend-btn:focus {
    outline: none;
    box-shadow: none;
}

.show-legend-btn:active {
    outline: none;
    box-shadow: none;
    background-color: #DCECF9;
}

.show-legend-btn:hover {
    background-color: #F0F7FC !important;
}

.legendDetail {
    height: 100%;
    width: 0;
    z-index: 1;
    overflow-x: hidden;
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    transition: 0.5s;
    font-family: "futura-pt",helvetica,arial,sans-serif;
    font-size: 14px;
}

.outageDetail {
    height: 100%;
    width: 0;
    top: 0px !important;
    z-index: 2;
    overflow-x: hidden;
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    transition: 0.5s;
    font-family: "futura-pt",helvetica,arial,sans-serif;
    font-size: 14px;
}

.pay-center-detail {
    height: 100%;
    width: 0;
    top: 0px !important;
    z-index: 2;
    overflow-x: hidden;
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    transition: 0.5s;
    font-family: "futura-pt",helvetica,arial,sans-serif;
    font-size: 14px;
}

.paylocation-direction-btn {
    width: 180px;
    height: 32px;
    background-color: white;
    border: 2px solid #087ED8;
    color: #087ED8;
    cursor: pointer;
}

.paylocation-direction-btn:hover {
    background-color: #F0F7FC;
    color: #087ED8;
}

.paylocation-direction-btn:active {
    background-color: #DCECF9 !important;
}

.paylocation-direction-btn:focus {
    outline: none;
    box-shadow: none;
}

@media (min-width: 992px) {
    .pay-location-map {
        height: 600px;
        width: 100%;
    }

    .outage-map-style {
        height: 600px;
        width: 100%;
    }

    .paylocation-direction-btn {
        width: 180px;
        height: 32px;
        background-color: white;
        border: 2px solid #087ED8;
        color: #087ED8;
        cursor: pointer;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .pay-location-map {
        height: 600px;
        width: 100%;
    }

    .outage-map-style {
        height: 600px;
        width: 100%;
    }

    .paylocation-direction-btn {
        width: 84px;
        height: 72px;
        background-color: white;
        border: 2px solid #087ED8;
        color: #087ED8;
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .pay-location-map {
        height: 270px;
        width: 100%;
    }

    .outage-map-style {
        height: 270px;
        width: 100%;
    }

    .paylocation-direction-btn {
        width: 84px;
        height: 72px;
        background-color: white;
        border: 2px solid #087ED8;
        color: #087ED8;
        cursor: pointer;
    }
}

.payment-location-closest-box-style {
    border: 1px solid lightgrey;
    border-radius: 10px;
    min-height: 149px;
    width: 200px;
    padding: 10px;
}

.payment-location-closest-box-style-small {
    border: 1px solid lightgrey;
    border-radius: 10px;
    min-height: 102px;
    width: 280px;
    padding: 10px;
}

.retail-orange {
    color: #CC8A00;
}

.paycenter-blue {
    color: #6787B7;
}