.showPassword-btn{
    background: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.button-focus:focus{
    outline: 0;
}

ul.login-list {
    padding: 0;
    padding-left: 20px
}

.login-list {
    color: rgb(103, 135, 183);
}

span.login-list {
    color: black;
}

.password-verification {
    color: #737b4c;
    padding-top: '5px';
}

.pointer-btns{
    cursor: pointer;
}

/*need a new login*/
.need-new-login-alert-details {
    padding: 0.5rem;
}

.need-new-login-alert-body-r,
.need-new-login-alert-body {
    border: 1px solid;
    border-color: #f4e7cc;
    background-color: #f4e7cc;
    border-radius: 3px;
}

.srp-tooltip {
    cursor: pointer;
    position: relative;
    text-align: center;
}

.srp-tooltip-text {
    background: rgb(84, 110, 122);
    border-radius: 10px;
    bottom: 100%;
    color: #fff;
    display: block;
    left: -110px;
    margin-bottom: 10px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    width: 250px;
    -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        -ms-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
            transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.srp-tooltip .srp-tooltip-text:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid rgb(84, 110, 122) 10px;
    bottom: -8px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0
}

.srp-tooltip:hover .srp-tooltip-text {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
            transform: translateY(0px);
}

/* For items that are first in a list, or you just want the popup to appear under */
.srp-tooltip-text-bottom {
    background: rgb(84, 110, 122);
    border-radius: 10px;
    top: 32px;
    color: #fff;
    display: block;
    left: -110px;
    margin-bottom: 10px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    width: 250px;
    -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        -ms-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
            transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.srp-tooltip .srp-tooltip-text-bottom:before {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-bottom: solid rgb(84, 110, 122) 10px;
    top: -8px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0
}

.srp-tooltip:hover .srp-tooltip-text-bottom {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
            transform: translateY(0px);
}

.loading-page-h {
    color: white;
}

.srp-logo-loading-w{
    width: 130px
}

@media (min-height: 1000px) {
    .loading-page-h {
        height: 900px
    }
}

@media (max-height: 999px) {
    .loading-page-h {
        height: 600px
    }
}