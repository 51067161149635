.step-container {
    display: flex;
    flex-direction: row;
}

.badge-active-step {
    background-color: rgb(0, 75, 135, 1);
    color: #f0f7fc;
    height: 1.7em;
    padding: 0;
    margin: 0;
    width: 1.7em;
}

.badge-pending-step {
    background-color: rgb(103, 135, 183, 1);
    color: #f0f7fc;
    height: 1.7em;
    padding: 0;
    margin: 0;
    width: 1.7em;
}

.step-line-text:after{
    color: #ccc;
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
    margin-left: 1em;
    text-decoration-line: line-through;
    text-decoration-style: dotted;
}