
.header-day {
  font-size: 4em;
  font-weight: bold;
}

.header-month {
  font-size: 1.8em;
  font-weight: lighter;
}

.month {
  display: flex;
  justify-content: center;
  align-items: center;  
}

.month-title {
  text-align: center;
  flex: 4;
}

.month-year {
  font-size: 0.8em;
  color: #aaa;
}

.footer {
  width: 100%;
  min-width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  text-align: center;
  cursor: default;
}

.day-headers {
  flex-basis: 14%;
  justify-content: center;
  align-items: center;
  padding: 8px 0px 8px 0px;
  font-weight: bold;
}

.header {
  width: 100%;
  min-width: 290px;
  background-color: #336699;
  padding: 60px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.day {
  flex-basis: 14%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  position: relative;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section-header {
  font-size: 1.125rem; 
  font-weight: bold;
  color: rgb(72, 72, 74)
}

.calendar {
  width: 350px;
}

.calendar-dates {
  color: #707070;
  height: 36px;
  width: 36px;
  line-height: 12px;
}

.calendar-dates.today {
  border: 1px solid #087ED8;
  font-weight: bold;
}

.calendar-dates:disabled {
  color: #70707042 !important;
}

.calendar-dates:focus {
  background-color: #a2cef0 !important;
}

.calendar-dates:hover {
  background-color: #e6f2fb !important;
}

.calendar-dates.selected {
  background-color: #087ed8;
  color: white;
}

.calendar-dates.selected:focus {
  background-color: #087ed8 !important;
  color: white !important;
}

.calendar-dates.selected:hover {
  background-color: #087ed8 !important;
  color: white !important;
}

.stepper-label-mobile {
  font-size: 0.75rem;
}

.srp-chip-selected {
    background-color: #087ED8 !important;
}

.srp-chip-outlined {
    border-color: #087ED8 !important;
    color: #087ED8 !important;
    background-color: #ffffff !important;
}