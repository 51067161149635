#cash-payment-card {
    border-radius: 2px;
    border: 1px dashed #aaaaaa;
    background-color: #ffffff;
    box-sizing: border-box;
  }
.cc-text{

    padding: 2px 2px 4px 2px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "FuturaPT-Medium", "Futura PT Medium", "Futura PT Book", "Futura PT", sans-serif;
    font-weight: 500;
    color: #333333;
    text-align: left;
    line-height: normal;
    font-size: 10px;
  }
  .full-page-div{
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
  }
  .centerFlex {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  #cash-card-logo{
      height: 72px;
      width: 128px;
  }
  #icc-img{
    height: 54px;
    width: 42px;
  }
  #bar-code{
    height:72px;
  }
  .cc-a, .cc-a:visited, .cc-a:active{
    color: inherit;
    font-weight: 800;
  }
  #you-have-reached-this {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "FuturaPT-Bold", "Futura PT Bold", "Futura PT Book", "Futura PT", sans-serif;
    font-weight: 700;
    color: #333333;
    text-align: left;
    line-height: normal;
  }
  #used-a-bookmark {
       height: 22px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "FuturaPT-Medium", "Futura PT Medium", "Futura PT Book", "Futura PT", sans-serif;
    font-weight: 500;
    color: #333333;
    text-align: left;
    line-height: normal;
    display:inline-block;
    width: 90%;
  }