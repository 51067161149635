.filtered-item {
    cursor: pointer;
    width:100%;
}

.filtered-item:hover{
    background-color: #E0E7F0;
}

#filtered-list {
    list-style-type: none;
    padding:0;
    margin:0;
}

.ellipsis-overflow{
    max-width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.greyBottomBorder {
    border-bottom: solid 1px #e0e0e0;
}