.srp-table-head {
    font-size: 13px;
    color: #707070;
}

.srp-table-body {
    font-size: 15px;
}

.srp-table-footer {
    font-size: 15px;
    color: #707070;
}

.account-history-col-view {
    width: 44px !important;
    text-align: center !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-billDate {
    width: 119px !important;
    text-align: left;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-balanceForward {
    width: 100px !important;
    text-align: right !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-newCharges {
    width: 80px !important;
    text-align: right !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-bbpAmountDue {
    width: 189px !important;
    text-align: right !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-dueDate {
    width: 116px !important;
    text-align: left;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-amountPaid {
    width: 170px !important;
    text-align: right !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-date {
    width: 180px !important;
    text-align: left;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-fees {
    width: 109px !important;
    text-align: left;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-usageBillDate {
    min-width: 68px !important;
    text-align: left;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.account-history-col-readDate {
    min-width: 130px !important;
    text-align: left;
    padding-left: 10px !important;
    padding-right: 10px !important;
}


.account-history-usage-col {
    text-align: right !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.srp-table-button {
    text-decoration: none;
    color:  #087ED8;
}

.srp-table-button:hover  {
    color:  #0771C2;
}

.srp-table-button:active  {
    color:  #0664AC;
}

.srp-table-bg-light-grey {
    background-color: rgba(0,0,0,0.04);
}

.srp-table-row-responsive {
    border-width: 1px 1px 1px 1px;
    border-color: rgb(179,195,219) !important;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
}

.table-border-separate {
    border-collapse: separate !important;
}