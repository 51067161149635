.payment-nav .nav-item .nav-link .newWindowIcon {
    display: inline-block;
}

.payment-nav .nav-item .nav-link .newWindowIconSelected {
    display: none;
}

.payment-nav .nav-item .nav-link.active .newWindowIcon {
    display: none;
}

.payment-nav .nav-item .nav-link.active .newWindowIconSelected {
    display: inline-block;
}

.payment-warning-color {
    color: #b15455
}

.bank-info-icon-position {
    left: 8rem;
    top: -0.65rem;
}

.bank-info-icon-position-es {
    left: 9.8rem;
    top: -0.7rem;
}

.multi-payment-date-picker input{
    max-width: 100px;
}

.multi-payment-date-picker span.material-icons {
    color: rgb(8, 126, 216);
}

.multi-payment-date-picker-mobile input {
    min-width: 75%
}

.multi-payment-date-picker-mobile span.material-icons {
    color: rgb(8, 126, 216);
}

.multipayment-large-header {
    border-bottom: 3px solid rgba(0, 75, 135, 1);
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 15px;
}

.thick-blue-border-top {
    border-top: 3px solid rgba(0, 75, 135, 1);
}

.thick-blue-border-bottom {
    border-bottom: 3px solid rgba(0, 75, 135, 1);
}

.multipayment-menu-clickable-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.multipayment-filter-menu-header {
    font-size: 18px;
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-bottom: 16px !important;
}

.multipayment-filter-menu-header:hover {
    background-color: transparent !important;
    cursor: default !important;
}

multipayment-filter-menu-header-clear-all {
    color: rgb(8,126,216) !important;
    font-size: 16px !important;
}

.multipayment-filter-menu-header-clear-all:hover {
    cursor: pointer !important;
}

.pat-purchase-row[class*="selected"],
.pat-purchase-row-responsive[class*="selected"] .pat-payment-row-responsive.ngp {
    background-color: rgb(239,243,247) !important;
}

.pat-purchase-row-responsive:hover,
.pat-purchase-row-responsive[class*="selected"] {
    background-color: transparent !important;
}

.pat-payment-row:hover {
    -webkit-appearance: none;
    -webkit-box-shadow: 1px 5px 2px -3px rgb(170, 170, 170);
    -moz-box-shadow: 1px 5px 2px -3px rgb(170, 170, 170);
    box-shadow: 1px 5px 2px -3px rgb(170, 170, 170);
}

.pat-payment-row:hover .pat-input-box {
    background-color: white;
}

.pat-payment-row:hover .pat-input-box.ngp,
.pat-ngp-header-responsive {
    border-color:rgb(204, 219, 231) !important;
}

.pat-ngp-header-responsive {
    background-color: rgb(229, 237, 243) !important;
}

.pat-payment-row:hover td {
    border-bottom: 1px solid transparent;
}

.pat-payment-row-disabled:hover {
    -webkit-appearance: none;
    -webkit-box-shadow: 1px 5px 2px -3px rgb(170, 170, 170);
    -moz-box-shadow: 1px 5px 2px -3px rgb(170, 170, 170);
    box-shadow: 1px 5px 2px -3px rgb(170, 170, 170);
}

.pat-payment-row-disabled:hover td {
    border-bottom: 1px solid transparent;
}

.mui-collapsed-panel-no-line:before {
    background-color: transparent !important;
}

.pat-tr-disabled {
    border-bottom: 0px solid transparent !important;
}

.pat-header-responsive {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.04);
}

.pat-input-box-responsive {
    background-color: white;
}

.pat-tr-separator {
    border-width: 0px 0px 1px 0px;
    border-color: rgb(179,195,219) !important;
    border-style: solid;
}

.pat-tr-separator-visible {
    border-width: 1px 0px 1px 0px;
    border-color: rgb(179,195,219) !important;
    border-style: solid;
}

.pat-payment-row-responsive-left {
    border-width: 1px 0px 1px 1px;
    border-color: rgb(179,195,219) !important;
    border-radius: 5px 0px 0px 5px;
    border-style: solid;
}

.pat-payment-row-responsive-right {
    border-width: 1px 1px 1px 0px;
    border-color: rgb(179,195,219) !important;
    border-radius: 0px 5px 5px 0px;
    border-style: solid;
}

.pat-payment-row-responsive-left-parent {
    border-width: 1px 0px 0px 1px;
    border-color: rgb(179,195,219) !important;
    border-radius: 5px 0px 0px 0px;
    border-style: solid;
    border-bottom: 0px transparent !important;
}

.pat-payment-row-responsive-right-parent {
    border-width: 1px 1px 0px 0px;
    border-color: rgb(179,195,219) !important;
    border-radius: 0px 5px 0px 0px;
    border-style: solid;
    border-bottom: 0px transparent !important;
}

.pat-payment-row-responsive {
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 0 1px rgb(179,195,219);
    -moz-box-shadow: 0 0 0 1px rgb(179,195,219);
    box-shadow: 0 0 0 1px rgb(179,195,219);
    border-radius: 5px;
}

.pat-ngp-header-responsive .pat-payment-row-responsive.ngp {
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.pat-payment-row-responsive.ngp,
.pat-ngp-header-responsive {
    margin: 0.5rem 0.5rem 0 0.5rem;
}

.pat-ngp-header-responsive.ngp {
    margin: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.pat-scheduled-payment-top-left {
    border-color: rgb(179,195,219);
    border-width: 1px 0px 0px 1px;
    border-radius: 5px 0px 0px 0px;
    border-style: solid;
}

.pat-scheduled-payment-top-right {
    border-color: rgb(179,195,219);
    border-width: 1px 1px 0px 0px;
    border-radius: 0px 5px 0px 0px;
    border-style: solid;
}

.pat-scheduled-payment-bottom-left {
    border-color: rgb(179,195,219);
    border-width: 0px 0px 1px 1px;
    border-radius: 0px 0px 0px 5px;
    border-style: solid;
}

.pat-scheduled-payment-bottom-right {
    border-color: rgb(179,195,219);
    border-width: 0px 1px 1px 0px;
    border-radius: 0px 0px 5px 0px;
    border-style: solid;
}

.pat-summary-account-parent-control-responsive {
    border-width: 0px 1px 1px 1px;
    border-color: rgb(179,195,219) !important;
    border-radius: 0px 0px 5px 5px;
    border-style: solid;
}
.pat-summary-account-container-responsive {
    margin-left: -24px;
    margin-right: -24px;
}

.summary-account-expand-multipayment {
    color:rgb(8,126,216);
    font-size: 16px;
    font-weight: 400;
    padding: 0px;
}

/* @include media-breakpoint-up(md) { ... } */
@media (min-width: 768px) {
    .ngp-confirm-button {
        width: auto !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles. Credit: https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/ */
    .bank-info-icon-position-ie-fix {
        margin-top: 0.05rem
    }
}

@media print {
    /*
    Force ExpansionPanel on single purchase submitted page to be expanded when printing.
    Adapted from https://stackoverflow.com/questions/62123503/force-expansionpanel-to-be-expanded-when-printing
    */
    div.purchase-details > div:nth-child(2) {
        min-height: auto !important;
        height: auto !important;
        visibility: visible !important;
    }
}

.col-width-42 {
    column-width: 42px;
}

.ccp-circle {
    height: 42px;
    width: 42px;
    color: white;
    background-color: #004987;
    text-align: center;
}